import React from 'react'
import { Flex, Box } from 'reflexbox'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import SbEditable from 'storyblok-react'
import Markdown from 'src/components/Markdown/Markdown'
import {
  LogotypeSami,
  LogotypeFryshuset,
  LogotypeGrammis,
  LogotypeLiveNation,
  LogotypeSpotify,
  LogotypeLuger,
  LogotypeUniversal,
  LogotypeWarner,
  LogotypeSony,
  LogotypeMMF,
  LogotypeStim,
} from 'src/components/UI/Logotype/Logotype'
import classnames from 'classnames'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import * as styles from './Footer.module.scss'
import { Props } from './types'

const Footer = ({ blok }: Props): JSX.Element => {
  const { partners, contact_title, contact } = blok || {}

  return (
    <SbEditable content={blok}>
      <Container
        marginTop="auto"
        as="footer"
        className={styles.wrapper}
        paddingY={[10, null, null, '6vw']}
        bg="black"
      >
        <Flex flexWrap="wrap" marginBottom={[10, null, null, 10]}>
          {partners &&
            partners.length > 0 &&
            partners.map((partner) => {
              console.log(partner.partners.length)
              const length = partner.partners.length
              return (
                <Box
                  width={[1, null, null, length / 8]}
                  marginBottom={4}
                  key={partner._uid}
                >
                  <Flex>
                    <Box
                      className={classnames(styles.label, styles.divider)}
                      marginBottom={[1, null, null, 2]}
                    >
                      {partner.title}
                    </Box>
                  </Flex>
                  <Flex flexWrap="wrap">
                    {partner.partners &&
                      partner.partners.length &&
                      partner.partners.map((partner) => (
                        <Flex
                          className={styles.sponsorBlock}
                          width={[1 / 3, null, null, 1 / length]}
                          key={partner._uid}
                        >
                          <LinkComponent
                            className={styles.sponsor}
                            to={partner.link.url}
                          >
                            <img
                              src={partner.image.filename}
                              alt={partner.image.alt}
                            />
                          </LinkComponent>
                        </Flex>
                      ))}
                  </Flex>
                </Box>
              )
            })}
        </Flex>
        <Row>
          <Column width={[1, null, null, 2 / 6]}>
            <Box marginBottom={[10, null, null, 0]}>
              {contact_title && (
                <Box className={styles.label} marginBottom={[2, null, null, 4]}>
                  {contact_title}
                </Box>
              )}
              {contact && <Markdown>{contact}</Markdown>}
            </Box>
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default Footer
