import { SbLink } from 'src/storyblok/types/storyblokTypes'

export const getLinkAttributes = (link: SbLink) => {
  const url = link?.url?.cached_url
    ? link.url.cached_url
    : link?.url?.email
    ? `mailto:${link.url.email}`
    : ''

  const title = link?.title ? link.title : ''

  return {
    url,
    title,
  }
}
