export const getSeo = (seo: any, seoContent?: any) => {
  let seoData = { seo_description: '', seo_title: '', seo_image: '' }

  if (Array.isArray(seo)) {
    seoData = seo.length > 0 ? seo[0] : {}
  } else {
    seoData = seo
  }

  const { seo_description, seo_title, seo_image } = seoData ? seoData : {}
  const { description, title, image } = seoContent ? seoContent : {}

  return {
    seo_description: description || seo_description || '',
    seo_title: title || seo_title || '',
    seo_image: image
      ? image
      : seo_image?.filename?.length > 0
      ? seo_image?.filename
      : '',
  }
}
