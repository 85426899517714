import React from 'react'
import Helmet from 'react-helmet'
import Header from 'src/storyblok/GlobalModules/Header/Header'
import Footer from 'src/storyblok/GlobalModules/Footer/Footer'
import Menu from 'src/components/Menu/Menu'
import { Flex, Box } from 'reflexbox'
import SEO from 'src/components/SEO/SEO'
import { getSeo } from 'src/helpers/getSeo'
import background from 'src/images/background-2023.jpg'

import * as styles from './Layout.module.scss'
import { Props } from './types'

export default ({ children, pageContext }: Props): JSX.Element => {
  const { globalData, story }: any = pageContext ? pageContext : {}
  const { header, footer, seo, site }: any = globalData ? globalData : {}

  const seo_data: any = seo ? seo.content : {}
  const { seo_description, seo_title, seo_image } = getSeo(seo_data, {
    title: '',
    description: '',
    image: '',
  })

  return (
    <Flex
      flexDirection="column"
      minHeight="100vh"
      className={styles.layout}
      id="layout"
    >
      <React.Fragment>
        {seo_title && (
          <Helmet
            titleTemplate={`%s — ${seo_title}`}
            defaultTitle={seo_title}
          />
        )}
        <SEO
          title={story?.name && story?.slug !== 'home' ? story.name : ''}
          metaDescription={seo_description}
          image={seo_image}
        />
      </React.Fragment>

      {header?.content?.links && <Menu links={header.content.links} />}
      {header?.content && <Header blok={header.content} />}
      <Box className={styles.background}>
        <img src={background} alt="" />
      </Box>
      <main className={styles.page}>{children}</main>
      {footer?.content && <Footer blok={footer.content} />}
    </Flex>
  )
}
