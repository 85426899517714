// extracted by mini-css-extract-plugin
export var logoGrid = "Footer-module--logoGrid--1uP6u";
export var item = "Footer-module--item--1xAXE";
export var sponsors = "Footer-module--sponsors--11vdy";
export var wrapper = "Footer-module--wrapper--2H_Xw";
export var display = "Footer-module--display--JiKsx";
export var description = "Footer-module--description--115ky";
export var heading = "Footer-module--heading--2bda2";
export var label = "Footer-module--label--1GG8N";
export var divider = "Footer-module--divider--JWTCO";
export var contact = "Footer-module--contact--YkIaQ";
export var icon = "Footer-module--icon--2hECp";
export var sponsor = "Footer-module--sponsor--3D_NK";
export var sponsorBlock = "Footer-module--sponsorBlock--PnEXE";